export const props = {
  logShow: {
    type: Function,
    default: () => () => {},
  },
  photoShow: {
    type: Function,
    default: () => () => {},
  },
};
