var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Box',[_c('div',{staticClass:"page-service-jobTask"},[_c('Search',{on:{"on-search":_vm.handle_search}}),_c('Table',{staticClass:"task-table",attrs:{"columns":_vm.columns,"dataSource":_vm.dataSource,"pagination":_vm.pagination},on:{"size-change":(size) => {
          _vm.pagination.pageSize = size;
          _vm.async_get_list();
        },"page-change":(page) => {
          _vm.pagination.page = page;
          _vm.async_get_list();
        }},scopedSlots:_vm._u([{key:"action",fn:function({ row }){return [_c('div',{staticClass:"action"},[_c('span',{staticClass:"iconfont icon-chulixuqiu",attrs:{"title":"日志回放"},on:{"click":function($event){return _vm.logShow(row)}}}),_c('span',{staticClass:"iconfont icon-tuxiangku",attrs:{"title":"图片回放"},on:{"click":function($event){return _vm.photoShow(row)}}})])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }