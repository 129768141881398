<template>
  <div class="cpt-task-list-search">
    <div style="display: flex; align-items: center">
      <el-form
        :model="search_form"
        style="margin-left: 24px"
        class="linear-form"
        inline
      >
        <el-form-item label="任务名称" >
          <el-input
            v-model="search_form.name"
            size="mini"
            placeholder="请输入任务名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="所属单位:" class="aaxx">
          <el-cascader
          class="input-orgname"
          placeholder="请选择所属单位"
          v-model="search_form.organizationId"
          :options="selectList"
          :show-all-levels="false"
          :props="{
            children: 'child',
            label: 'name',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
            empty: false
          }"
          clearable
        >
        </el-cascader>
        </el-form-item>

        <el-form-item label="任务状态:">
          <el-select v-model="search_form.taskStatus" size="mini" clearable placeholder="请选择任务状态">
            <el-option
              v-for="item in drd.status"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>

        


        <el-form-item label="任务类型:">
          <el-select v-model="search_form.taskCateId" size="mini" clearable placeholder="请选择任务类型">
            <el-option
              v-for="item in drd.cates"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        
        <el-form-item label="">
          <div @click="$emit('on-search', search_form)" class="linear-btn">
            搜索
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { props } from "./props";
import API from "@/api";
import { mapGetters } from "vuex";
export default {
  props: _.pick(props, ["onEdit"]),
  data() {
    return {
      selectList: null,
      isEasy: false,
      search_form: {
        organizationId: null,
        taskStatus: null,
        taskCateId: null,
        name:null,
        status:null,
      },
      // drd: {
      //   status: [
      //     { id: 1, title: "启用" },
      //     { id: 0, title: "禁用" },
      //   ]
      // },
      drd: {
        cates: [
          { id: 2, title: "常态任务" },
          { id: 3, title: "临时紧急任务" },
        ],
        scenes: null,
        status: [
          { id: 0, title: "待执行" },
          { id: 1, title: "已完成" },
          { id: 3, title: "执行中" },
          { id: "-1", title: "已取消" },
        ],
        apply: [
          { id: 0, title: "待审批" },
          { id: 1, title: "审批通过" },
          { id: 2, title: "审批不通过" },
        ],
      },
    };
  },
  methods: {
    async getAllOrganization() {
      let { data } = await API.AIRWAY.getAllOrganizationBack()
			this.selectList = this.getTreeData(data)
    },
    getTreeData(data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i].child.length < 1) {
					// children若为空数组，则将children设为undefined
					data[i].child = undefined;
				} else {
					// children若不为空数组，则继续 递归调用 本方法
					this.getTreeData(data[i].child);
				}
			}
			return data;
		},
  },
  mounted() {
    this.getAllOrganization()
  },
    computed:{
    ...mapGetters(["user_info_hn"]),
  },
};
</script>

<style lang="scss" scoped>
.cpt-task-list-search {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
::v-deep .el-input{
  width: 178px;
  .el-input__inner{
    width: 100% !important;
  }
}
</style>