<template>
  <Box>
    <div class="page-service-jobTask">
      <Search
        @on-search="handle_search"
      />
      <Table
        class="task-table"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="pagination"
        @size-change="
          (size) => {
            pagination.pageSize = size;
            async_get_list();
          }
        "
        @page-change="
          (page) => {
            pagination.page = page;
            async_get_list();
          }
        "
      >
        <template #action="{ row }">
          <div class="action">
            <span title="日志回放" class="iconfont icon-chulixuqiu" @click="logShow(row)"></span>
            <span title="图片回放" class="iconfont icon-tuxiangku" @click="photoShow(row)"></span>
          </div>
        </template>
      </Table>
    </div>
  </Box>
</template>

<script>
import Box from "@/components/platform/common/box";
import Search from "./search.vue";
import Table from "@/components/common/table";
import { props } from "./props";
import API from "@/api";
export default {
  props: _.pick(props, ["logShow", "photoShow"]),
  components: { Box, Search, Table },
  methods: {},
  data() {
    return {
      columns: [
        { prop: "id", label: "序号" },
        { prop: "taskTitle", label: "任务名称" },
        { prop: "applyStatusName", label: "审批状态" },
        { prop: "dutyOrganName", label: "需求单位" },
        { prop: "taskCateName", label: "任务类型" },
        { prop: "taskTypeName", label: "场景类型" },
        { prop: "startedAt", label: "任务开始时间" },
        { prop: "taskStatusName", label: "任务状态" },
        { prop: "action", slot: true, label: "操作" },
      ],
      dataSource: null,
      map: {
        status: {
          "-1": "已取消",
          0: "待执行",
          1: "已完成",
          // 2: "执行中",
          3: "执行中",
        },
        apply: {
          0: "待审批",
          1: "审批通过",
          2: "审批不通过",
          3: "默认通过",
        },
      },
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      search: {},
    };
  },
  methods: {
    async async_get_list() {
      const id = this.$route.params.id;
      const { map } = this;
      const { page, pageSize } = this.pagination;
      const res = await API.TASK.taskPage({
        ...this.search,
        pageNum: page,
        pageSize: pageSize,
        deviceHardId: id
      });
      
      const data = res.data;
      this.dataSource = data.data || [];
      this.dataSource = this.dataSource.map((item)=>({
        ...item,
        taskStatusName: map.status[item.taskStatus],
        applyStatusName: map.apply[item.papplyStatus],
      }))
      this.pagination.page = data.pageNo;
      this.pagination.total = data.totalCount;
    },
    handle_search(data) {
      this.pagination.page = 1;
      this.search = data;
      this.async_get_list();
    },

  },
  mounted() {
    this.async_get_list();
  },
};
</script>

<style lang="scss" scoped>
.page-service-jobTask {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
  .task-table {
    flex: 1;
    margin-top: 27px;
    .action {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      .iconfont {
        cursor: pointer;
        font-size: 26px;
        color: #9fc4ff;
        margin-right: 4px;
        line-height: 26px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>