<template>
  <div class="page-task-list">
    <TaskList
      :logShow="
        (value) => {
          log(value);
        }
      "
      :photoShow="
        (value) => {
          photo(value);
        }
      "
      ref="listRef"
    />

    <el-dialog
      title="日志回放"
      v-if="visible.logShow"
      :visible.sync="visible.logShow"
      width="935px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="logTable">
        <div style="width: 436px; height: 479px;">
          <!-- <video
            style="width: 436px; height: 479px"
            :src="videoslist"
            controls
            autoplay
          ></video> -->
          <VideoPlay v-if="videoslist" :datalist="videoslist" />
        </div>
        <div class="tableRight">
          <div class="tableRight-down">
            <div class="linear-btn" @click="downLog">下载日志</div>
            <div class="linear-btn log" >历史轨迹</div>
          </div>
          <div class="logBody">
          <div class="props-box">
            <div class="prop-field">
              <div class="prop-field-k">无人机名称：</div>
              <div class="prop-field-v">{{ logData.deviceName }}</div>
            </div>
            <div class="prop-field">
              <div class="prop-field-k">无人机ID：</div>
              <div class="prop-field-v">{{ logData.deviceHardId  }}</div>
            </div>
            <div class="prop-field">
              <div class="prop-field-k">飞行开始时间：</div>
              <div class="prop-field-v">{{logData.addTime}}</div>
            </div>
            <div class="prop-field">
              <div class="prop-field-k">飞行结束时间：</div>
              <div class="prop-field-v">{{logData.endTime}}</div>
            </div>
            <div class="prop-field">
              <div class="prop-field-k">所属任务：</div>
              <div class="prop-field-v">{{ logData.taskTitle }}</div>
            </div>
            <div class="prop-field">
              <div class="prop-field-k">场景类型：</div>
              <div class="prop-field-v">{{ logData.taskTypeName }}</div>
            </div>
            <div class="prop-field">
              <div class="prop-field-k">任务类型：</div>
              <div class="prop-field-v">{{ logData.taskCateName }}</div>
            </div>
            <div class="prop-field">
              <div class="prop-field-k">责任单位：</div>
              <div class="prop-field-v">{{logData.organizationName}}</div>
            </div>
            <div class="prop-field">
                <div class="prop-field-k">飞行时长：</div>
                <div class="prop-field-v">{{ logData.flightTime != null ? logData.flightTime + 's' : '暂无' }}</div>
              </div>
              <div class="prop-field">
                <div class="prop-field-k">飞行距离：</div>
                <div class="prop-field-v">{{ logData.flightDistance != null ? logData.flightDistance + 'm' : '暂无' }}</div>
              </div>
          </div>
        </div>
        </div>
      </div>
    </el-dialog>

    <div class="img-container">
      <viewer style="width: 100%"></viewer>
    </div>

  </div>
</template>

<script>
import API from "@/api";
import TaskList from "./list";
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import VideoPlay from "@/components/device/player/vidoeoJs";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  components: {
    TaskList,
    VideoPlay
  },
  data() {
    return {
      visible: {
        logShow: false,
        photoShow: false,
      },
      value1: 0,
      key: null,
      logData: null,
      videoslist: null,
      tableList: null,
    };
  },
  methods: {
    // 打开日志推送接口
    async log(value) {
     this.logList = value
     const res = await API.TASK.taskLog({
       taskId: value.id,
       type: 0,
     })
     if (res.status === 1) {
        this.key = res.data.key
        this.logData = res.data
        this.visible.logShow = true;
        this.videoslist = res.data.videos.length >= 1 ? res.data.videos[0] : null
    } else {
      this.$message.error(res.message)
    }
   },
    // 照片预览
    async photo(value) {
      const res = await API.TASK.GetAchievementList({
        id: value.id,
        pageSize: 10000,
        typeId: 1,
      });
      if(res.data.data.length > 0) {
        let photolist = [];
        res.data.data.map((item) => photolist.push(item.storage));
        this.visible.photoShow = true;
        this.$viewerApi({
          images: photolist,
        });
      } else {
        this.$el_message('暂无历史照片')
      }
      
    },
    sliderChange() {
    },
    // 关闭日志弹框
    handleClose(done) {
      done && done();
      // this.clear()
      
    },
    // 日志下载
    async downLog(data) {
      try {
        const blob = await API.TASK.exportData({
          taskId: data.taskId,
        });
        if (blob) {
          let binaryData = [];
          binaryData.push(blob);
          const a = document.createElement("a");
          a.href = URL.createObjectURL(new Blob(binaryData));
          a.download = `${this.tableList.taskTitle}.xls`;
          a.click();
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  inject: ["toggle_mode"],
};
</script>

<style lang="scss" scoped>
.page-task-list {
  box-sizing: border-box;
  padding: 32px;
  height: 100%;
  .task-list-notice-wrap {
    display: flex;
    .map-box {
      width: 436px;
      height: 274px;
      border: 1px solid red;
      flex-shrink: 0;
      box-sizing: border-box;
      margin-right: 16px;
    }
    .props-box {
      flex: 1;
      box-sizing: border-box;
      .prop-field {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0px;
        }
        .prop-field-k {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          text-align: left;
          font-weight: 400;
          width: 98px;
        }
        .prop-field-v {
          font-size: 14px;
          color: #cce8ff;
          letter-spacing: 0;
          font-weight: 400;
        }
      }
    }
  }
  .airwayNum {
    height: 36px;
    line-height: 36px;
    font-size: 17px;
    color: #9fc4ff;
    padding: 0 20px;
  }
}
</style>

<style lang='scss'>
.logTable {
  display: flex;
  .tableRight {
    padding-left: 16px;
    width: 436px;
    &-down{
      display: flex;
      .log{
        margin-left: 50px;
      }
    }
  }
  .logBody {
    padding-top: 14px;
    display: flex;
    .props-box  {
      .prop-field {
        margin-bottom: 26px;
      }
    }
  }
  .logFooter{
    display: flex;
    align-items: center;
    .block {
      width: 220px;
    }
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
    .el-slider__button {
      width: 11px;
      height: 11px;
    }
  }
  
  .props-box {
    flex: 1;
    box-sizing: border-box;
    .prop-field {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0px;
      }
      .prop-field-k {
        font-size: 17px;
        color: #cce8ff;
        letter-spacing: 0;
        text-align: left;
        font-weight: 400;
        width: 120px;
      }
      .prop-field-v {
        font-size: 17px;
        color: #cce8ff;
        letter-spacing: 0;
        font-weight: 400;
        
      }
    }
  }
}

.img-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  .img {
    width: 31% !important;
    height: 200px !important;
    margin: 6px 12px 6px 0px !important;
    border: #009aff solid 1px !important;
  }
}
</style>