<template>
    <div>
      <List />
    </div>
</template>

<script>
import List from "./list"
export default {
  components: {
    List
  }
}
</script>

<style>
.el-scrollbar.el-cascader-menu {
  max-height: 400px;
  overflow-y: auto;
}
</style>